import React from 'react';
import { RiskColumnDefinitionData } from './common';
import RiskCardTable from '../risks/RiskCardTable';
import { generateInternalHeaderLink, getTableCounter } from '../../../common/common-components';
import i18n from '../../../i18n';

export interface RiskCardsProps {
  riskItems: RiskColumnDefinitionData[];
  isLoadingRisks: boolean;
  hasMoreRisks: boolean;
  isMutating: boolean;
  totalRiskCount: number;
}

export interface RiskCardDefinitionData {
  readonly ruleName: string;
  readonly ruleId: string;
  readonly description: string;
  readonly severity: string;
  readonly items: RiskColumnDefinitionData[];
}

export const PAGE_SIZE = 10;

export const RISK_CARDS_ID = 'risk-cards';

const findRuleRiskCount = (ruleId: string, cardItems: readonly RiskCardDefinitionData[]) => {
  const ruleIndex = cardItems.findIndex((item) => item.ruleId === ruleId);
  return cardItems[ruleIndex]?.items.length;
};

const findRuleSelectedCount = (ruleId: string, selectedItems: RiskColumnDefinitionData[]) => {
  return selectedItems.reduce((count, item) => {
    return item.ruleId === ruleId ? count + 1 : count;
  }, 0);
};

const getSelectionDiff = (cardsSelectedItems: RiskCardDefinitionData[], newCardSelection: RiskCardDefinitionData[]) => {
  return cardsSelectedItems.filter((item) => !newCardSelection.includes(item)).map((item) => item.ruleId);
};

export const riskCountByRuleMap = (riskItems: RiskColumnDefinitionData[]) => {
  return riskItems.reduce((rules, item) => {
    return rules.set(item.ruleId, (rules.get(item.ruleId) || 0) + 1);
  }, new Map<string, number>());
};

export const allRisksInCardSelected = (riskByRule: Map<string, number>, ruleCard: RiskCardDefinitionData) => {
  return ruleCard.items.length === riskByRule.get(ruleCard.ruleId);
};

export const getUpdatedRiskSelection = (
  currentCardSelection: RiskCardDefinitionData[],
  currentRiskSelection: RiskColumnDefinitionData[],
  newCardSelection: RiskCardDefinitionData[]
) => {
  const unselectedCardsRules = getSelectionDiff(currentCardSelection, newCardSelection);
  const risksWithoutUnselectedCards = [...currentRiskSelection].filter(
    (riskItem) => !unselectedCardsRules.includes(riskItem.ruleId)
  );
  const newRiskSelection = newCardSelection.flatMap((card) => card.items);
  for (const risk of newRiskSelection) {
    if (!risksWithoutUnselectedCards.includes(risk)) {
      risksWithoutUnselectedCards.push(risk);
    }
  }
  return risksWithoutUnselectedCards;
};

export const aggregateRisksByRule = (riskItems: RiskColumnDefinitionData[]) => {
  const ruleArray = [] as RiskCardDefinitionData[];
  riskItems.forEach((item) => {
    const rule = ruleArray.find((e) => e.ruleId === item.ruleId);
    if (rule) {
      rule.items.push(item);
    } else {
      ruleArray.push({
        ruleName: item.ruleName,
        ruleId: item.ruleId,
        description: item.description,
        severity: item.severity,
        items: [item]
      });
    }
  });
  return ruleArray;
};

export const getCardDefinition = (
  selectedItems: RiskColumnDefinitionData[],
  cardItems: readonly RiskCardDefinitionData[],
  hasMoreRisks: boolean | undefined,
  isLoadingRisks: boolean
) => {
  return {
    header: (e: RiskCardDefinitionData) => {
      return generateInternalHeaderLink(
        `/rules/${e.ruleId}`,
        e.ruleName,
        getTableCounter(
          findRuleSelectedCount(e.ruleId, selectedItems),
          findRuleRiskCount(e.ruleId, cardItems),
          hasMoreRisks
        )
      );
    },
    sections: [
      {
        id: 'description',
        header: i18n.t('Description'),
        content: (cardRisks: RiskCardDefinitionData) => cardRisks.description,
        width: 50
      },
      {
        id: 'severity',
        header: i18n.t('Severity'),
        content: (cardRisks: RiskCardDefinitionData) => cardRisks.severity,
        width: 50
      },
      {
        id: 'risks',
        content: (cardRisks: RiskCardDefinitionData) => {
          return <RiskCardTable riskItems={cardRisks.items} ruleId={cardRisks.ruleId} isLoading={isLoadingRisks} />;
        }
      }
    ]
  };
};
